import React, { useState, useEffect, useRef } from 'react';

export default function Schedule() {
  const [activeTab, setActiveTab] = useState('one');
  const tabContentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (tabContentRef.current) {
      const tabContents = tabContentRef.current.querySelectorAll('.tab-pane');
      const heights = Array.from(tabContents).map((content) => content.scrollHeight);
      setMaxHeight(Math.max(...heights));
    }
  }, [tabContentRef]);

  return (
    <section id="schedule" className="relative text-center font-tattoo p-8">
      <div className="relative z-10 px-6 sm:px-8 lg:px-24 text-white">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-primary mb-6">Schedule</h1>
        <div className="flex flex-col md:flex-row items-start justify-between gap-6">
          {/* Tabs Section */}
          <div className="md:flex-1 bg-white bg-opacity-10 rounded-lg p-4 md:p-6 text-left">
            <div className="tab-card">
              <div className="card-header">
                <ul className="flex border-b overflow-x-auto" role="tablist">
                  <li className="mr-1">
                    <button
                      className={`py-2 px-4 rounded-t focus:outline-none ${
                        activeTab === 'one'
                          ? 'bg-primary text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-primary hover:text-white'
                      }`}
                      onClick={() => setActiveTab('one')}
                      id="one-tab"
                      role="tab"
                      aria-controls="one"
                      aria-selected={activeTab === 'one'}
                    >
                      Day 1
                    </button>
                  </li>
                  <li className="mr-1">
                    <button
                      className={`py-2 px-4 rounded-t focus:outline-none ${
                        activeTab === 'two'
                          ? 'bg-primary text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-primary hover:text-white'
                      }`}
                      onClick={() => setActiveTab('two')}
                      id="two-tab"
                      role="tab"
                      aria-controls="two"
                      aria-selected={activeTab === 'two'}
                    >
                      Day 2
                    </button>
                  </li>
                  <li>
                    <button
                      className={`py-2 px-4 rounded-t focus:outline-none ${
                        activeTab === 'three'
                          ? 'bg-primary text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-primary hover:text-white'
                      }`}
                      onClick={() => setActiveTab('three')}
                      id="three-tab"
                      role="tab"
                      aria-controls="three"
                      aria-selected={activeTab === 'three'}
                    >
                      Day 3
                    </button>
                  </li>
                </ul>
              </div>

              <div
                className="tab-content w-full overflow-hidden mt-4"
                id="myTabContent"
                ref={tabContentRef}
                style={{ minHeight: `${maxHeight}px` }}
              >
                <div
                  className={`tab-pane p-6 bg-black text-white rounded-b-lg ${
                    activeTab === 'one' ? 'block' : 'hidden'
                  }`}
                  id="one"
                  role="tabpanel"
                  aria-labelledby="one-tab"
                >
                  <ul className="list-disc pl-6">
                    <li>1pm – Doors open to public</li>
                    <li>2:30pm – Small Colour Tattoo</li>
                    <li>3:30pm – Medium Black &amp; Grey Tattoo</li>
                    <li>5pm – Large Colour Tattoo</li>
                    <li>7pm – Colour Sleeve</li>
                    <li>
                      9pm – Tattoo of the Day (must be started and finished at
                      the show)
                    </li>
                    <li>10pm – Close</li>
                  </ul>
                </div>

                <div
                  className={`tab-pane p-6 bg-black text-white rounded-b-lg ${
                    activeTab === 'two' ? 'block' : 'hidden'
                  }`}
                  id="two"
                  role="tabpanel"
                  aria-labelledby="two-tab"
                >
                  <ul className="list-disc pl-6">
                    <li>12pm – Doors open to public</li>
                    <li>2:30pm – Small Black and Grey Tattoo</li>
                    <li>3:30pm – Medium Colour Tattoo</li>
                    <li>5pm – Large Black &amp; Grey Tattoo</li>
                    <li>7pm – Black &amp; Grey Sleeve</li>
                    <li>
                      9pm – Tattoo of the Day (must be started and finished at
                      the show)
                    </li>
                    <li>10pm – Close</li>
                  </ul>
                </div>

                <div
                  className={`tab-pane p-6 bg-black text-white rounded-b-lg ${
                    activeTab === 'three' ? 'block' : 'hidden'
                  }`}
                  id="three"
                  role="tabpanel"
                  aria-labelledby="three-tab"
                >
                  <ul className="list-disc pl-6">
                    <li>12pm – Doors open to the public</li>
                    <li>2pm – Back piece</li>
                    <li>3pm – Best Collection</li>
                    <li>5pm – Tattoo of the Day and Best of Show</li>
                    <li>6pm – Close</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Logo Section */}
          <div className="md:w-1/3 flex justify-center items-center">
            <div className="w-[70%] sm:w-[50%] md:w-[80%] lg:w-[90%]">
              <a href="https://goodguysupply.com/">
                <img
                  src="/images/logo--goodguy.png"
                  alt="GoodGuy Tattoo Supply"
                  className="rounded-lg shadow-lg w-full h-auto hover:scale-105 hover:opacity-90 transition duration-300 ease-in-out"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
