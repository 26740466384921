import React, { useEffect, useState } from 'react';
import { FaInstagram } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { FiGlobe } from 'react-icons/fi';
import { motion } from 'framer-motion';

export default function Artists() {
  const [artists, setArtists] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    // Fetch artist metadata from JSON file
    fetch('/metadata/SJTC--attending-artists-2025.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Shuffle the artists
        const shuffled = data.sort(() => Math.random() - 0.5);
        setArtists(shuffled);
      })
      .catch((error) => {
        console.error('Error fetching artist data:', error);
      });
  }, []);

  // Determine the artists to display
  const displayedArtists = showAll ? artists : artists.slice(0, 5);

  return (
    <section id="artists" className="p-8 text-center font-tattoo relative">
      {/* Heading */}
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 text-primary">
        Attending Artists
      </h2>

      {/* Toggle Button */}
      <div className="mb-4">
        <button
          onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
          className="bg-primary text-black font-bold py-2 px-6 rounded-lg hover:bg-white hover:text-primary transition duration-300"
        >
          {showAll ? 'Show Less' : 'View All Attending Artists'}
        </button>
      </div>

      {/* Artists Grid */}
      <motion.div
        className="overflow-y-auto" // Add vertical scrolling
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={{
          maxHeight: showAll ? 'none' : '50vh', // Constrain height when not showing all
        }}
      >
        <div className="flex flex-wrap justify-center gap-6">
          {displayedArtists.map((artist, index) => {
            const [firstName, lastName] = artist.ARTIST.split(' ');
            return (
              <motion.div
                key={index}
                className="bg-gray-700 p-1 rounded-lg shadow-md w-full sm:w-64 flex flex-col items-center border-4 border-white hover:scale-105 transform transition duration-300 ease-in-out"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <img
                  src={`/images/${artist.IMAGE}`}
                  alt={artist.ARTIST}
                  className="w-full h-auto rounded-md hover:opacity-80 transition duration-200"
                />
                <h3 className="text-xl md:text-2xl font-medium mt-2">
                  <span>{firstName}</span>
                  <br />
                  <span>{lastName}</span>
                </h3>
                <div className="mt-3 w-full px-4 flex justify-center gap-4">
                  {artist.INSTAGRAM && (
                    <a
                      href={`https://instagram.com/${artist.INSTAGRAM}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-white transition duration-200"
                    >
                      <FaInstagram className="text-3xl" />
                    </a>
                  )}

                  {artist.WEBSITE && (
                    <a
                      href={`https://${artist.WEBSITE}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-white transition duration-200"
                    >
                      <FiGlobe className="text-3xl" />
                    </a>
                  )}

                  {artist.EMAIL && (
                    <a
                      href={`mailto:${artist.EMAIL}`}
                      className="text-primary hover:text-white transition duration-200"
                    >
                      <MdEmail className="text-3xl" />
                    </a>
                  )}
                </div>
              </motion.div>
            );
          })}
        </div>
      </motion.div>
    </section>
  );
}
