import React from 'react';

export default function About() {
  return (
    <section 
      id="about" 
      className="relative text-center font-tattoo p-8"
    >
      {/* Content on top of background image */}
      <div className="relative z-10 lg:px-24 text-white">
        <h1 className="text-5xl md:text-6xl font-bold text-primary mb-6">About The SJTC</h1>
        <div className="flex flex-col md:flex-row items-center md:items-start justify-between">
          {/* Responsive Image */}
          <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
            <img
              src="/images/image--dave.jpg"
              alt="Dave Munro"
              className="w-full max-w-xs md:max-w-sm lg:max-w-md h-auto rounded-lg shadow-md"
            />
          </div>
          
          {/* History Content */}
          <div className="md:w-1/2 text-left">
            <p className="text-base md:text-lg lg:text-xl mb-4">
              The St. John's Tattoo Convention (SJTC) is a visually stunning celebration of tattoo artistry and community that is held in St. John's, Newfoundland, the oldest city in North America. St. John's is known for its unique culture, vivid nightlife and inspiring natural beauty.
              Over the years SJTC has continued to showcase world-class talent and craftsmanship in a truly Newfoundland way and has grown into a coveted event for Tattooists and Collectors. SJTC was founded over a decade ago by Dave Munro and brought to you by <a href="https://troubleboundtattoos.com/" className="text-primary hover:text-white transition duration-200">Trouble Bound Studio</a> which has been a cornerstone of custom tattooing in Newfoundland for over 20 years.
            </p>
            <p className="text-base md:text-lg lg:text-xl mb-4">
              Join us in the celebration of the art of tattooing, connect with renowned artists, all while experiencing the unique culture of St. John's at this unforgettable event.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
