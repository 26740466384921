// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Artists from './components/Artists'; // Assuming this is a section component for the main page
import Info from './components/Info';
import Contact from './components/Contact';
import AttendingArtists from './components/AttendingArtists';
import Schedule from './components/Schedule';
import Accomodation from './components/Accomodation';
import Presentedby from './components/Presentedby';

function App() {
  return (
    <Router>
      <div className="bg-darkBg text-white min-h-screen border-b border-gray-800">
        <Routes>
          {/* Main page route */}
          <Route 
            path="/" 
            element={
              <>
                <Header />
                <Info />
                <div className="border-t border-gray-800">
                  <Artists />
                </div>
                <div className="border-t border-gray-800">
                  <About />
                </div>
                <div className="border-t border-gray-800">
                  <Schedule />
                </div>
                <div className="border-t border-gray-800">
                  <Accomodation />
                </div>
                <div className="border-t border-gray-800">
                  <Presentedby />
                </div>
                <div className="border-t border-gray-800">
                  <Contact />
                </div>
              </>
            } 
          />
          
          {/* Updated attending artists page route */}
          <Route path="/attending-artists" element={<AttendingArtists />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
