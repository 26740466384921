import React from 'react';

export default function Info() {
  return (
    <section 
      id="info" 
      className="relative text-center font-tattoo py-24"
    >
      {/* Content on top of background image */}
      <div className="relative z-10 px-8 lg:px-24 text-white">
        <h1 className="text-5xl md:text-6xl font-bold text-primary mb-6">The 9<sup>th</sup> Annual St. John's Tattoo Convention</h1>
        <h2 className="text-2xl md:text-3xl font-bold text-white mb-6">June 28<sup>th</sup> - June 30<sup>th</sup>, 2025</h2>
        <h3 className="text-xl md:text-xl font-bold text-white mb-6">The Re/Max Centre <br />
        135 Mayor Avenue <br />
        St. John's, Newfoundland
        </h3>
        <div className="mt-4">
          <button className="bg-primary text-black font-bold py-2 px-6 rounded-lg hover:bg-white hover:text-primary transition duration-300" disabled>
            Tickets Available - Soon
          </button>
        </div>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 mt-12 text-white">Proudly Presented By</h2>
        <p className="text-base md:text-lg lg:text-xl mb-4 font-bold text-white">
         <a href="https://troubleboundtattoos.com/"  className="text-primary hover:text-white transition duration-200">Trouble Bound Studio</a>
        </p>

      </div>
    </section>
  );
}
