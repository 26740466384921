import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone, FaInstagram, FaTwitter, FaFacebook } from 'react-icons/fa';
import { MdAccessTime } from 'react-icons/md';

export default function Contact() {
  return (
    <section id="contact" className="p-8 text-center font-tattoo">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 text-primary">Contact Us</h2>
      <div className="flex flex-col items-center text-base md:text-xl lg:text-2xl space-y-4">
        <div className="flex items-center">
          <FaEnvelope className="text-primary mr-3" />
          <a href="mailto:info@stjohnstattooconvention.com" 
          className="text-primary hover:text-white transition duration-200">
          info@stjohnstattooconvention.com
          </a>
        </div>
        <div className="flex items-center">
          <FaMapMarkerAlt className="text-primary mr-3" />
          <span>135 Mayor Avenue, St. John's NL</span>
        </div>
        <div className="flex items-center">
          <FaPhone className="text-primary mr-3" />
          <span>709-754-5590</span>
        </div>
        <div className="flex items-center">
          <MdAccessTime className="text-primary mr-3" />
          <span>June 28<sup>th</sup> - June 30<sup>th</sup>, 2025</span>
        </div>
      </div>


          {/* Social Media Icons Row */}
          <div className="flex justify-center mt-8 space-x-4 text-base md:text-lg lg:text-xl">
            <a
              href="https://instagram.com/st.johnstattooconvention"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-white transition duration-200"
            >
              <FaInstagram className="text-2xl" />
            </a>
            <a
              href="https://twitter.com/TBS_StJohns"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-white transition duration-200"
            >
              <FaTwitter className="text-2xl" />
            </a>
            <a
              href="https://facebook.com/pages/St-Johns-Tattoo-Convention/651882704883558"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-white transition duration-200"
            >
              <FaFacebook className="text-2xl" />
            </a>
          </div>
        </section>
  );
}
