// src/components/AttendingArtists.js
import React, { useEffect, useState } from 'react';
import { FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import Header from './Header';

export default function AttendingArtists() {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    // Fetch the metadata file
    fetch('/metadata/SJTC--attending-artists-2025.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setArtists(data);
      })
      .catch((error) => {
        console.error('Error fetching artist data:', error);
      });
  }, []);

  return (
    <div className="bg-darkBg text-white min-h-screen">
      {/* Reuse Header Component */}
      <Header />

      {/* Custom Layout for Attending Artists Page */}
      <section id="artists" className="p-8 text-center font-tattoo">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 text-primary">
          Attending Artists
        </h2>
        <div className="flex flex-wrap justify-center gap-6">
          {artists.map((artist, index) => {
            const [firstName, lastName] = artist.ARTIST.split(' ');
            return (
              <a
                key={index}
                href={artist.WEBSITE || artist.INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gray-700 p-1 rounded-lg shadow-md w-full sm:w-64 flex flex-col items-center border-4 border-white hover:scale-105 transform transition duration-300 ease-in-out"
              >
                <img
                  src={`/images/${artist.IMAGE}`}
                  alt={artist.ARTIST}
                  className="w-full h-auto rounded-md hover:opacity-80 transition duration-200"
                />
                <h3 className="text-xl md:text-2xl font-medium mt-2">
                  <span>{firstName}</span>
                  <br />
                  <span>{lastName}</span>
                </h3>
                <div className="flex items-center text-brightRed hover:text-primary mt-2 transition duration-200">
                  <FaInstagram className="mr-2" /> {artist.INSTAGRAM}
                </div>
                <div className="flex items-center text-gray-300 mt-2">
                  <FaMapMarkerAlt className="mr-2" /> {artist.LOCATION}
                </div>
              </a>
            );
          })}
        </div>
      </section>
    </div>
  );
}
