// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import classNames from 'classnames';

export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [pastHeader, setPastHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Current scroll position
      const nav = document.querySelector('nav');
      const navTop = nav.offsetTop; // Navigation's original position
      const headerHeight = document.querySelector('header > div').offsetHeight; // Header image height

      // Set sticky state when scrolling past the nav's original position
      setIsSticky(scrollTop > navTop);

      // Stop sticky behavior when scrolling back above the header
      setPastHeader(scrollTop > headerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header>
      {/* Main Header Image Section */}
      <div className="relative text-center font-tattoo">
        <img 
          src="images/logo--SJTC.jpg" 
          alt="Trouble Bound Studio"
          className="w-full h-auto max-h-[900px] object-cover"
        />
      </div>

      {/* Navigation Bar */}
      <nav
        className={classNames(
          'bg-menuBg text-white w-full p-4 md:p-6 shadow-lg z-50 transition-all duration-500 ease-out',
          { 
            'fixed top-0 transform translate-y-0 opacity-100': isSticky && pastHeader, // Sticky
            'relative transform opacity-90': !pastHeader // Non-sticky with slight transition
          }
        )}
      >
        <ul className="flex flex-wrap justify-center space-x-3 md:space-x-8 lg:space-x-16 font-tattoo text-xs md:text-base lg:text-lg uppercase font-extrabold">
          <li>
            <ScrollLink 
              to="info" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              className="text-white hover:text-primary cursor-pointer transition duration-200"
            >
              Info
            </ScrollLink>
          </li>
          <li>
            <ScrollLink 
              to="artists" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              className="text-white hover:text-primary cursor-pointer transition duration-200"
            >
              Artists
            </ScrollLink>  
          </li>
          <li>
            <ScrollLink 
              to="about" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              className="text-white hover:text-primary cursor-pointer transition duration-200"
            >
              About
            </ScrollLink>
          </li>
          <li>
            <ScrollLink 
              to="schedule" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              className="text-white hover:text-primary cursor-pointer transition duration-200"
            >
              Schedule
            </ScrollLink>
          </li>
          <li>
            <ScrollLink 
              to="presentedby" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              className="text-white hover:text-primary cursor-pointer transition duration-200"
            >
              TBS
            </ScrollLink>
          </li>
          <li>
            <ScrollLink 
              to="contact" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              className="text-white hover:text-primary cursor-pointer transition duration-200"
            >
              Contact
            </ScrollLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}
