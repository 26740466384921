import React from 'react';
import { FaGlobe } from 'react-icons/fa';

export default function Accomodation() {
  return (
    <section id="accomodation" className="p-8 text-center font-tattoo">
      <div className="max-w-screen-xl mx-auto">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 text-primary">Accomodation</h2>
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between gap-6">
          {/* Left Column - Image with Link */}
          <div className="w-full lg:w-1/4 text-base md:text-lg lg:text-xl text-left mt-4 lg:mt-0 lg:pl-6">
            <p>
            The Guv’nor Inn & Pub is a charming old-English inspired property offering comfortable, relaxed accommodation and great food in a convenient location in the center of St John's. Contact <a href="mailto:info@stjohnstattooconvention.com" class="text-primary hover:text-white transition duration-200">Ally</a> for booking information.
            </p>
            <p className="mt-4 flex items-center">
              <FaGlobe className="text-primary mr-2" />
              <a href="https://theguvnor.ca/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-white transition duration-200">
               Website
              </a>
            </p>
          </div>


          {/* Right Column - Content */}
          <div className="w-full lg:w-3/4 border-4 border-primary hover:border-deepRed transition duration-300 ease-in-out rounded-lg">
            <a href="https://theguvnor.ca/" target="_blank" rel="noopener noreferrer">
              <img 
                src="/images/image--accomodation--guvnor.jpg" 
                alt="The Guv'Nor" 
                className="rounded-lg shadow-lg w-full h-auto hover:scale-105 hover:opacity-90 transition duration-300 ease-in-out"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
